@use "src/scss/variables";

.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: variables.$menu-background-color;
  height: 100vh;
  z-index: 4;
  text-align: left;
  padding: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  //   transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    letter-spacing: 0.5rem;
    color: variables.$menu-text-color;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 768) {
      font-size: 1rem;
      text-align: center;
      padding: 1rem 0;
    }

    &:hover {
      color: #f0f;
    }
  }
}

.menu-item {
  font-size: 2rem;
  max-width: 350px;
  text-transform: uppercase;
  padding: 2rem 0;
  letter-spacing: 0.5rem;
  color: variables.$menu-text-color;
  text-decoration: none;
  transition: color 0.3s linear;
  cursor: pointer;

  @media (max-width: 768) {
    font-size: 1rem;
    text-align: center;
    padding: 1rem 0;
  }

  &:hover {
    color: #f0f;
  }
}
