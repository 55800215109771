.new-customer-form-input {
  flex-basis: 33%;
  margin-right: 50px;
  min-width: 200px;
}
.create-customer-button-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;

  @media (max-width: 768px) {
    max-width: 360px;
  }
}
.new-customer-info-message {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 80px;
}
