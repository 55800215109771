.new-product-container {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.create-product-header {
  padding: 30px 0 30px 0;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.new-product-form-container {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 600px;

  @media (min-width: 768px) {
    margin-left: 200px;
  }
}
.new-product-form-input {
  flex-basis: 33%;
  margin-right: 50px;
  min-width: 200px;
}
.create-product-button-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;
}
.new-product-info-message {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 80px;
}
.new-category-form-select {
  margin-right: 15px;
  margin-top: 28px;
  min-width: 200px;
  @media (max-width: 768px) {
    min-width: 100px;
  }
}
