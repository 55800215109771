@use "src/scss/variables";

.profile-menu-container {
  display: flex;
  flex-direction: column;
  background: variables.$mobile-menu-background-color;
  height: 160px;
  z-index: 1;
  padding: 10px 10px;
  text-align: left;
  position: absolute;
  bottom: 90px;
  right: 0;
  transition: transform 0.3s ease-in-out;
  border-radius: 20px;
  cursor: pointer;
  //   transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
}

.profile-menu-item {
  font-size: 1rem;
  text-align: center;
  padding: 1rem 0;
  color: variables.$menu-text-color;
  text-decoration: none !important;
  transition: color 0.3s linear;
  cursor: pointer;

  &:hover {
    color: #f0f;
  }
}

.profile-menu-item-link {
  text-decoration: none !important;
}
