.cut-text {
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cut-text-centered {
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
}
