.user-feedback-container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 375px;
  }
}
