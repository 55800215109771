.reminder-mobile-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 800px;
  margin-bottom: 11px;

  @media (max-width: 768px) {
    width: 360px;
  }
}

.reminder-mobile-section {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-radius: 1.5rem;
  border-width: 1px;
  padding: 10px 0px;
  //background-color: #c3ecda;
  background-image: linear-gradient(to bottom right, #c3ecda, #83ac9a);
}
.reminder-mobile-section-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #906088;
  font-size: 2rem !important;
  width: 70px !important;
  @media (max-width: 768px) {
    width: 50px !important;
  }
}
.reminder-mobile-section-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
