@use "src/scss/variables";
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #ececed;
}
.middle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  @media (max-width: 768px) {
    top: 40%;
  }
}
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.loading-header-text {
  font-size: 48px;
  color: rgb(144, 96, 136);
  flex-grow: 1;
  text-align: center;
  align-self: center;

  @media (max-width: 768px) {
    font-size: 24px;
    min-width: 240px;
  }
}
.bar {
  width: 10px;
  height: 70px;
  background: #ececed;
  display: inline-block;
  transform-origin: bottom center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  /*   box-shadow:5px 10px 20px inset rgba(255,23,25.2); */
  animation: loader 1.2s linear infinite;
}
.bar1 {
  animation-delay: 0.1s;
}
.bar2 {
  animation-delay: 0.2s;
}
.bar3 {
  animation-delay: 0.3s;
}
.bar4 {
  animation-delay: 0.4s;
}
.bar5 {
  animation-delay: 0.5s;
}
.bar6 {
  animation-delay: 0.6s;
}
.bar7 {
  animation-delay: 0.7s;
}
.bar8 {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    transform: scaleY(0.1);
    //   background: ;
  }
  50% {
    transform: scaleY(1);
    background: rgb(144, 96, 136);
  }
  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}
