.customer-details-header-container {
  display: flex;
  flex-direction: row;
  max-width: 800px !important;
  width: 800px;
  font-size: 40px;
  @media (max-width: 768px) {
    max-width: 400px !important;
    width: 370px;
  }
}
.customer-details-header-text {
  font-weight: 100;
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 1rem;
  padding-right: 1rem;
}
