.customer-mobile-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 800px;
  margin-bottom: 11px;

  @media (max-width: 768px) {
    width: 360px;
  }
}

.customer-mobile-section {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-radius: 1.5rem;
  border-width: 1px;
  padding: 10px 0px;
  background-color: #c3ecda;
}
.customer-mobile-section-content {
  display: flex;
  width: 150px !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
