.orders-table-header {
  display: flex;
  flex-direction: row;

  .orders-table-header-text {
    font-weight: 100;
    font-size: 24px;

    @media (min-width: 768px) {
      flex-grow: 1;
    }
  }
}
