.reminder-modal {
  @media (min-width: 768px) {
    width: 360px;
  }
  display: contents !important;
}
.MuiDialog-container {
  @media (max-width: 768px) {
    width: 100% !important;
    height: 100%;
  }
}
.MuiDialog-paper {
  border-radius: 2rem !important;
  @media (max-width: 768px) {
    margin: 0px !important;
    width: 360px !important;
    max-width: 360px;
  }
}
.reminder-modal-background {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 2px solid #000;
  background-color: #c3ecda !important;
  padding: 20px;
  border-radius: 2rem;
  height: 850px;

  @media (max-width: 768px) {
    padding: 10px 5px 10px 5px;
    height: 850px;
    width: 360px;
  }
}
.reminder-modal-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 800px;
  margin-bottom: 11px;
  @media (max-width: 768px) {
    width: 350px;
  }
}

.reminder-modal-section {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-radius: 1.5rem;
  border-width: 1px;
  padding: 26px 0px;
}
.reminder-modal-section-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #906088;
  font-size: 3.5rem !important;
  width: 100px;
}
.reminder-modal-section-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.reminder-modal-select {
  min-width: 200px;
  margin: 0;
  @media (max-width: 768px) {
    min-width: 100px;
  }
}

.reminder-modal-top {
  padding-left: 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    padding-left: 0px;
  }
}
.reminder-modal-top-title {
  flex-grow: 1;
  margin-top: 20px;
}
.reminder-modal-top-close {
  text-align: right;
  justify-content: end;
  justify-self: end;
  align-self: end;
  margin: 0;
  padding-top: 5px;
  padding-right: 10px;
  cursor: pointer;
}
.reminder-modal-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 800px;
}

.reminder-modal-input {
  flex-basis: 33%;
  margin-right: 50px;
  min-width: 200px;

  @media (max-width: 768px) {
    min-width: 100px;
  }
}
.reminder-modal-form {
  margin-left: 48px;
  margin-right: 48px;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.reminder-modal-ul {
  margin: 0px;
  @media (max-width: 768px) {
    padding: 0px;
  }
}
.reminder-modal-group {
  flex-direction: row;
  display: flex;
  align-content: center;
  justify-content: center;
}
.reminder-modal-number-input {
  flex-basis: 15%;
  margin-right: 30px;
  min-width: 80px;
  padding-top: 0px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-right: 15px;
  }
}
.create-order-button-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;
}
.new-order-info-message {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 80px;
}
.delete-button {
  color: purple;
}
