@use "src/scss/variables";

.mobile-menu-container {
  display: flex;
  flex-direction: column;
  background: variables.$menu-background-color;
  height: 300px;
  z-index: 4;
  text-align: left;
  position: absolute;
  top: 100px;
  left: 0;
  transition: transform 0.3s ease-in-out;
  //   transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};

  a {
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem 0;
    letter-spacing: 0.5rem;
    color: variables.$menu-text-color;
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
      color: #f0f;
    }
  }
}

.mobile-menu-item {
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem 0;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  color: variables.$menu-text-color;
  text-decoration: none;
  transition: color 0.3s linear;
  cursor: pointer;

  &:hover {
    color: #f0f;
  }
}
