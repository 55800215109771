.product-header {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;

  @media (max-width: 768px) {
    padding: 15px 0 15px 0;
  }
}
.product-data-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.product-table-header {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 768px) {
    align-items: center;
  }
  .product-table-header-text {
    font-weight: 100;
    font-size: 24px;

    @media (min-width: 768px) {
      flex-grow: 1;
    }
  }
}
.product-table-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
}
