@use "src/scss/variables";

.burger-open {
  position: absolute;
  top: 33px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: variables.$menu-text-color;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    @media (max-width: 768px) {
      background: variables.$menu-background-color;
    }
  }

  :first-child {
    transform: rotate(45deg);
  }
  :nth-child(2) {
    opacity: 0;
    transform: translateX(20px);
  }

  :nth-child(3) {
    transform: rotate(-45deg);
  }
}

.burger-closed {
  position: absolute;
  top: 33px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: variables.$menu-background-color;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  :first-child {
    transform: rotate(0);
  }
  :nth-child(2) {
    opacity: 1;
    transform: translateX(0);
  }

  :nth-child(3) {
    transform: rotate(0);
  }
}
