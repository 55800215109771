$information-300: #66daff !default;
$information-500: #153ea6 !default;
$information: (
  300: $information-300,
  500: $information-500,
) !default;

$positive-300: #61ff8d !default;
$positive-500: #4bb97e !default;
$positive-700: #358359 !default;
$positive-900: #153322 !default;
$positive: (
  300: $positive-300,
  500: $positive-500,
  700: $positive-700,
  900: $positive-900,
) !default;

$primary-300: #ff668f !default;
$primary-900: #cd486c !default;
$primary: (
  900: $primary-900,
) !default;

$warning-100: #ffdcbc !default;
$warning-500: #fc9b42 !default;
$warning: (
  500: $warning-500,
) !default;

$critical-100: #ffcdcd !default;
$critical-500: #d83f3f !default;
$critical: (
  100: $critical-100,
  500: $critical-500,
) !default;

$secondary-050: #f7f3fc !default;
$secondary-100: #d8c3ef !default;
$secondary-300: #e371ff !default;
$secondary-500: #6913be !default;
$secondary: (
  050: $secondary-050,
  100: $secondary-100,
  300: $secondary-300,
  500: $secondary-500,
) !default;

$neutral-050: #f7f7f7 !default;
$neutral-100: #ececed !default;
$neutral-200: #d0d0d1 !default;
$neutral-400: #89898d !default;
$neutral-500: #626267 !default;
$neutral-600: #58585c !default;
$neutral-800: #3b3b3e !default;
$neutral-700: #4b4b4e !default;
$neutral-900: #2a2a2b !default;
$neutral-black: #141414 !default;
$neutral: (
  050: $neutral-050,
  100: $neutral-100,
  200: $neutral-200,
  400: $neutral-400,
  500: $neutral-500,
  600: $neutral-600,
  700: $neutral-700,
  800: $neutral-800,
  900: $neutral-900,
  black: $neutral-black,
) !default;

$background-color: #e5e5e5;
$light-color: #fff;
$dark-color: #333;
$primary-color: #3c5663;
$purple: #ff6099;
$base-typography-color: #131313;

$header-background-color: #c3ecda;
$header-text-color: $primary-color;

$menu-background-color: $primary-color;
$menu-text-color: $light-color;
$mobile-menu-background-color: #3c5663;
$mobile-menu-text-color: #fff;
$mobile-menu-icon-color: #fff;
$mobile-menu-icon-active-color: #fff;

$header-height: 100px;
$footer-height: 100px;
