.modal {
  @media (min-width: 768px) {
    min-width: 500px;
  }
  display: contents !important;
}
.modal-background {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 2px solid #000;
  background-color: #c3ecda !important;
  padding: 20px;
}
.modal-top {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
}
.modal-top-title {
  flex-grow: 1;
}
.modal-top-close {
  text-align: right;
  justify-content: end;
  justify-self: end;
  align-self: end;
  margin: auto;
  padding: 0px;
  margin-left: 1rem;
  cursor: pointer;
}
.table-info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.table-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}
.edit-button-container {
  width: 100px;
  height: 100px;
  font-size: 40;
}
.button-row {
  display: flex;
  flex-direction: row;
}
.total-price-container {
  margin-right: 30px;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-right: 135px;
  }

  p {
    text-align: end;
  }
}
.note-container {
  margin-right: 30px;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-right: 135px;
  }

  p {
    text-align: start;
  }
}
