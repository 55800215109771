.new-order-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-width: 800px !important;
  @media (max-width: 768px) {
    max-width: 400px !important;
  }
}
.header-container {
  display: flex;
  flex-direction: row;
  max-width: 800px !important;
  width: 800px;
  font-size: 40px;
  @media (max-width: 768px) {
    max-width: 400px !important;
    width: 370px;
  }
  h1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex-grow: 1;
    padding-right: 2rem;

    @media (max-width: 768px) {
      padding-right: 1rem;
      margin-bottom: 0rem;
    }
  }
}
.spinner-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.new-order-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 800px;
  margin-bottom: 11px;
  @media (max-width: 768px) {
    width: 360px;
  }
}

.new-order-section {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-radius: 1.5rem;
  border-width: 1px;
  padding: 26px 0px;
  @media (max-width: 768px) {
    max-width: 360px;
  }
}
.new-order-section-no-padding {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-radius: 1.5rem;
  border-width: 1px;
}
.new-order-section-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #906088;
  font-size: 3.5rem !important;
  width: 100px;
}
.new-order-section-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.new-order-select {
  min-width: 200px;
  @media (max-width: 768px) {
    min-width: 100px;
  }
}
.new-order-info-text {
  flex-grow: 1;
  font-size: 20px;
  margin-top: 1rem;
  background-color: #c3ecda;
  border-radius: 20px;
  padding: 1rem 2rem;

  @media (max-width: 768px) {
    padding: 1rem 1rem;
    font-size: 16px;
  }

  p {
    margin-bottom: 0px;
  }
}

.new-order-form-input {
  margin-right: 40px;
  margin-left: 40px;

  @media (max-width: 768px) {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
}
.new-order-form-repetitative {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  min-width: 400px;

  @media (max-width: 768px) {
    min-width: 300px;
    max-width: 400px;
    padding-left: 5px;
  }
}

.new-order-form-list {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: 80%;
  min-width: 400px;
  @media (max-width: 768px) {
    min-width: 300px;
  }
}
.new-order-form-list-item {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: 80%;
  min-width: 400px;
  @media (max-width: 768px) {
    min-width: 300px;
  }
}
.new-order-form-select {
  margin-right: 15px;
  margin-top: 28px;
  min-width: 200px;
  @media (max-width: 768px) {
    min-width: 100px;
    max-width: 200px;
  }
}
.new-order-form-ul {
  margin: 0px;
  @media (max-width: 768px) {
    padding: 0px;
  }
}
.new-order-form-group {
  flex-direction: row;
  display: flex;
  align-content: center;
  justify-content: center;
  @media (max-width: 768px) {
    max-width: 400px;
  }
}
.new-order-form-number-input {
  flex-basis: 15%;
  margin-right: 30px;
  min-width: 80px;
  padding-top: 0px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-right: 15px;
  }
}
.create-order-button-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;

  @media (max-width: 768px) {
    padding: 20px 0 4px 0 !important;
  }
}
.add-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 400px;
  }
}
.new-order-info-message {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 80px;
}
.delete-button {
  color: purple;
}
