.create-user-form-input {
  flex-basis: 33%;
  margin-right: 50px;
  min-width: 200px;

  @media (max-width: 768px) {
    min-width: 150px;
    margin-right: 20px;
  }
}
