.new-product-container {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 800px;
}
.create-product-header {
  padding: 30px 0 30px 0;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  max-height: 200px;
}
.new-product-form-container {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 600px;

  @media (min-width: 768px) {
    margin-left: 200px;
  }
}

.sub-category-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 800px;
  margin-top: 30px;

  @media (min-width: 768px) {
    margin-left: 200px;
    max-width: 600px;
  }
}

.new-subcategory-input {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.new-product-form-input {
  flex-basis: 33%;
  margin-right: 50px;
  min-width: 200px;
}
.show-sub-prorducts-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 10px 0 10px 0;
}
.create-product-button-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;
}
.new-product-info-message {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 80px;
}
