@use "src/scss/variables";

.mobile-nav-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  height: 60px;
}
.navigation {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: variables.$mobile-menu-background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 10px;
  border-top: 1px solid variables.$mobile-menu-background-color;
}
.navigation ul {
  display: flex;
  width: 350px;
  max-width: 100%;
  padding: 0;
  margin-bottom: 10px;
}
.navigation ul li {
  list-style: none;
  position: relative;
  width: 20%;
  height: 60px;
  z-index: 3;
}
.navigation ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.profile-item-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}
.navigation ul li a .icon {
  position: relative;
  display: block;
  line-height: 65px;
  font-size: 2em;
  transition: 0.5s;
  color: variables.$mobile-menu-icon-color;
  padding-bottom: 25px;
}
.navigation ul li .profile-item-container .icon {
  position: relative;
  display: block;
  line-height: 65px;
  font-size: 2em;
  transition: 0.5s;
  color: variables.$mobile-menu-icon-color;
  padding-bottom: 25px;
}
.navigation ul li.active a .icon {
  transform: translateY(-20px);
  color: variables.$mobile-menu-icon-active-color;
}
.navigation ul li.active .profile-item-container .icon {
  transform: translateY(-20px);
  color: variables.$mobile-menu-icon-active-color;
}
.navigation ul li a .text {
  position: absolute;
  color: variables.$mobile-menu-text-color;
  padding: 2px 7px;
  margin: 5px -6px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  font-size: 0.9rem;
}
.navigation ul li .profile-item-container .text {
  position: absolute;
  color: variables.$mobile-menu-text-color;
  padding: 2px 7px;
  margin: 5px -6px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  font-size: 0.9rem;
}
.navigation ul li.active a .text {
  transform: translateY(-4px);
  opacity: 1;
}
.navigation ul li.active .profile-item-container .text {
  transform: translateY(-4px);
  opacity: 1;
}
.indicator {
  position: absolute;
  top: -25px;
  width: 70px;
  height: 70px;
  background: variables.$mobile-menu-background-color;
  border-radius: 50%;
  z-index: 2;
  transition: 0.5s;
  border-top: 1px solid variables.$mobile-menu-background-color;
}
.indicator::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -28px;
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 15px 18px variables.$mobile-menu-background-color;
}
.indicator::after {
  content: "";
  position: absolute;
  top: 5px;
  right: -28px;
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50%;
  box-shadow: -15px 18px variables.$mobile-menu-background-color;
}
.navigation ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(70px * 0));
}
.navigation ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(70px * 1));
}
.navigation ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(70px * 2));
}
.navigation ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(70px * 3));
}
.navigation ul li:nth-child(5).active ~ .indicator {
  transform: translateX(calc(70px * 4));
}
