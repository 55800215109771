.Toastify {
  &__toast {
    &-icon {
      display: none !important;
    }
    &-body {
      flex: none !important;
    }
    & > button {
      color: white;
      opacity: 1 !important;
      transition: 0.2s;
      &:hover {
        color: #d9d8d8;
      }
    }
  }
  &__close {
    &-button {
      margin-top: 10px;
    }
  }
}

.error-toast {
  background-color: #c92b3d !important;
  color: white !important;
}

.warning-toast {
  background-color: #ce6413 !important;
  color: white !important;
}

.success-toast {
  background-color: #357e3d !important;
  color: white !important;
}

.regular-toast {
  background-color: #ff6099 !important;
  color: white !important;
}

.toast-text {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
}
