@use "./variables";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

$blue: variables.$information-500;
//$indigo: #6610f2 !default;
$purple: variables.$secondary-500;
$pink: variables.$secondary-300;
$red: variables.$critical-500;
$orange: variables.$warning-500;
//$yellow: #ffc107 !default;
$green: variables.$positive-500;
$teal: variables.$positive-300;
$cyan: variables.$information-300;

$primary: variables.$light-color;
$secondary: variables.$dark-color;
//$success: $green !default;
//$info: $cyan !default;
//$warning: $yellow !default;
//$danger: $red !default;
//$light: $gray-100 !default;
//$dark: $gray-900 !default;

$info: variables.$purple;
$alert: variables.$warning-500;

//$spacer: 1rem !default;
//$spacers: (
//  0: 0,
//  1: $spacer * .25,
//  2: $spacer * .5,
//  3: $spacer,
//  4: $spacer * 1.5,
//  5: $spacer * 3,
//) !default;

$body-bg: variables.$neutral-100;
$body-color: variables.$neutral-black;

$link-color: variables.$information-500;

$font-family-base: "Inter", sans-serif;

$line-height-base: 1.3;
$line-height-lg: 1.6;

@import "../../node_modules/bootstrap/scss/bootstrap";
