.customer-header {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 0 30px 0;

    @media (max-width: 768px) {
        padding: 15px 0 15px 0;
    }
}
.customer-data-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    

    @media (min-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.customer-table-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    .customer-table-header-text {
        font-weight: 100;
        font-size: 24px;

        @media (min-width: 768px) {
            flex-grow: 1;
        }
    }
}
.customer-table-container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    width: 100%;
}