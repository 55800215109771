@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  @page {
    size: 21cm 29.7cm;
    margin: 15mm 15mm 15mm 15mm;
    position: relative;
    display: block;
  }

  html,
  body {
    height: auto !important;
  }
  .dx-scrollable-container,
  .dx-scrollable-content,
  .dx-datagrid-content,
  .dx-scrollable,
  .dx-scrollable-wrapper,
  .dx-gridbase-container {
    height: auto !important;
    min-height: auto !important;
  }

  .modal-background {
    display: contents !important;
  }
  .dialog {
    background-color: #fff !important;
  }
  .MuiBackdrop-root {
    background-color: #fff !important;
    display: none;
  }
  .button-row {
    display: none;
  }
  .all-content {
    display: none;
  }
  .header {
    display: none;
  }
  .menu-button {
    display: none;
  }
}
