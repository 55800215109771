.system-modal {
  @media (min-width: 768px) {
    min-width: 500px;
  }
  display: contents !important;
}
.system-modal-background {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 2px solid #000;
  background-color: #c3ecda !important;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px 20px 20px 20px;
  }
}
.system-modal-top {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    padding-left: 10px;
  }
}
.system-modal-top-title {
  flex-grow: 1;
  margin-top: 10px;
}
.system-modal-top-close {
  text-align: right;
  justify-content: end;
  justify-self: end;
  align-self: end;
  margin: 0;
  padding-top: 5px;
  padding-right: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-top: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
}
.system-modal-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 800px;
}

.system-modal-input {
  flex-basis: 33%;
  margin-right: 50px;
  min-width: 200px;

  @media (max-width: 768px) {
    min-width: 100px;
  }
}
.system-modal-repetitative {
  display: flex;
  flex-grow: 1;

  flex-basis: 80%;
  min-width: 400px;

  @media (max-width: 768px) {
    min-width: 300px;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
}

.system-modal-repetitative-mobile {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: 80%;
  min-width: 400px;

  @media (max-width: 768px) {
    min-width: 300px;
    margin-top: -30px;
  }
}
.system-modal-list {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: 80%;
  min-width: 400px;
  @media (max-width: 768px) {
    min-width: 300px;
  }
}
.system-modal-list-item {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: 80%;
  min-width: 400px;
  @media (max-width: 768px) {
    min-width: 300px;
  }
}
.system-modal-select {
  margin-right: 15px;
  margin-top: 28px;
  min-width: 200px;
  @media (max-width: 768px) {
    min-width: 100px;
  }
}
.system-modal-ul {
  margin: 0px;
  @media (max-width: 768px) {
    padding: 0px;
  }
}
.system-modal-group {
  flex-direction: row;
  display: flex;
  align-content: center;
  justify-content: center;
}
.system-modal-number-input {
  flex-basis: 15%;
  margin-right: 30px;
  min-width: 200px;
  padding-top: 0px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-right: 0px;
  }
}
.create-order-button-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;
}
.new-order-info-message {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 80px;
}
.delete-button {
  color: purple;
}
