.reminder-view-modal {
  display: contents !important;
  max-width: 500;
}
.reminder-view-modal-background {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 2px solid #000;
  background-color: #deb6b7 !important;
  padding: 20px;
  height: 580px;

  @media (max-width: 768px) {
    padding: 5px;
    height: 700px;
  }
}
.reminder-view-modal-top {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
}
.reminder-view-modal-top-title {
  flex-grow: 1;
}
.reminder-view-modal-top-close {
  text-align: right;
  justify-content: end;
  justify-self: end;
  align-self: end;
  margin: 0;
  padding-top: 5px;
  padding-right: 10px;
  cursor: pointer;
}
.reminder-view-modal-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 800px;
}

.reminder-view-modal-input {
  flex-basis: 33%;
  margin-right: 50px;
  max-width: 200px;

  @media (max-width: 768px) {
    min-width: 100px;
  }
}
.reminder-view-modal-repetitative {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: 80%;
  min-width: 400px;

  @media (max-width: 768px) {
    min-width: 300px;
  }
}

.reminder-view-modal-repetitative-mobile {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: 80%;
  min-width: 400px;

  @media (max-width: 768px) {
    min-width: 300px;
    margin-top: -30px;
  }
}
.reminder-view-modal-list {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: 80%;
  min-width: 400px;
  @media (max-width: 768px) {
    min-width: 300px;
  }
}
.reminder-view-modal-list-item {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: 80%;
  min-width: 400px;
  @media (max-width: 768px) {
    min-width: 300px;
  }
}
.reminder-view-modal-select {
  margin-right: 15px;
  margin-top: 10px;
  min-width: 200px;
  @media (max-width: 768px) {
    min-width: 100px;
  }
}
.reminder-view-modal-ul {
  margin: 0px;
  @media (max-width: 768px) {
    padding: 0px;
  }
}
.reminder-view-modal-group {
  flex-direction: row;
  display: flex;
  align-content: center;
  justify-content: center;
}
.reminder-view-modal-number-input {
  flex-basis: 15%;
  margin-right: 30px;
  min-width: 80px;
  padding-top: 0px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-right: 15px;
  }
}
.create-order-button-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;
}
.new-order-info-message {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 80px;
}
.delete-button {
  color: purple;
}
