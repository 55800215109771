.rosa-sloyfe-container {
  background-color: #f6dbe6;
  color: #000;
  border-radius: 20px;
  max-width: 600px;
  width: 300px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
}
.rosa-sloyfe-header-text {
  font-size: 24px;
  color: #cc2d6e;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bolder;
}
.rosa-sloyfe-paragraph-text {
  font-size: 20px;
  text-align: end;
  padding-right: 17px;

  @media (max-width: 768px) {
    flex-grow: 1;
  }
}
