.edit-order-container {
  display: flex;
  flex-direction: row;
  max-width: 800px !important;
  @media (max-width: 768px) {
    max-width: 400px !important;
  }
}
.edit-order-header-container {
  display: flex;
  flex-direction: row;
  max-width: 800px !important;
  width: 800px;
  font-size: 40px;
  @media (max-width: 768px) {
    max-width: 400px !important;
    width: 370px;
  }
  h1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex-grow: 1;
    padding-right: 2rem;

    @media (max-width: 768px) {
      padding-right: 1rem;
      margin-bottom: 0rem;
    }
  }
}
.new-order-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 800px;
  margin-bottom: 11px;
  @media (max-width: 768px) {
    width: 360px;
  }
}

.new-order-section {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-radius: 1.5rem;
  border-width: 1px;
  padding: 26px 0px;
}
.new-order-section-no-padding {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-radius: 1.5rem;
  border-width: 1px;
}
.new-order-section-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #906088;
  font-size: 3.5rem !important;
  width: 100px;
}
.new-order-section-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.new-order-select {
  min-width: 200px;
  @media (max-width: 768px) {
    min-width: 100px;
  }
}
.new-order-info-text {
  flex-grow: 1;
  font-size: 20px;
  margin-top: 1rem;
  background-color: #c3ecda;
  border-radius: 20px;
  padding: 1rem 2rem;

  @media (max-width: 768px) {
    padding: 1rem 1rem;
    font-size: 16px;
  }

  p {
    margin-bottom: 0px;
  }
  // marginTop: 10,
  // paddingRight: 30,
  // textAlign: "end",
}

.new-order-form-input {
  margin-right: 40px;
  margin-left: 40px;
}
.new-order-form-group {
  flex-direction: row;
  display: flex;
  align-content: center;
  justify-content: center;
  @media (max-width: 768px) {
    max-width: 400px;
  }
}
.create-order-button-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;

  @media (max-width: 768px) {
    padding: 20px 0 4px 0 !important;
  }
}
